<template>
  <h3 class="text-sm font-medium">My Portfolio</h3>
  <div
    class="
      grid
      w-full
      grid-cols-12
      gap-3
      mt-5
      sm:grid-cols-2
      md:grid-cols-8
      lg:grid-cols-4
      sm:w-7/12
    "
  >
    <div class="col-span-8 md:col-span-3 lg:col-span-2">
      <investment>
        <h4 class="text-sm font-medium text-invest-dark">Portfolio Balance</h4>
        <span class="mt-3 text-2xl font-bold text-invest-primary">
          {{ $filters.formatAmount($store.state.user.balance || 0) }}
        </span>
      </investment>
    </div>
    <div class="col-span-1 lg:col-span-2">
      <button type="button" class="btn btn-primary btn-big" @click="create">
        <div
          class="
            flex
            items-center
            justify-center
            w-8
            h-8
            mt-5
            bg-white
            rounded-full
          "
        >
          <ion-icon name="add-outline" class="text-lg text-invest-purple" />
        </div>
        <p class="mt-2 mb-3 text-xs">Create New Investment</p>
      </button>
    </div>
  </div>

  <create-investment ref="createInvestmentModal" />
</template>
<script lang="ts">
import { defineComponent, defineAsyncComponent, ref, nextTick } from 'vue';

export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const createInvestmentModal = ref<any>(null);
    const componentKey = ref<number>(0);

    const create = () => {
      componentKey.value++;
      nextTick(() => createInvestmentModal.value.open());
    };

    return { createInvestmentModal, create };
  },
});
</script>
