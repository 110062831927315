
import { defineComponent, defineAsyncComponent, ref, nextTick } from 'vue';

export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const createInvestmentModal = ref<any>(null);
    const componentKey = ref<number>(0);

    const create = () => {
      componentKey.value++;
      nextTick(() => createInvestmentModal.value.open());
    };

    return { createInvestmentModal, create };
  },
});
